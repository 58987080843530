<template>
  <div class="messagedetails">
    <van-nav-bar
      :title="title"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <div class="main">
      <div
        class="main_list"
      >
        <van-cell-group :border="false">
          <van-cell :border="false">
            <template #title>
              <div class="van-ellipsis"></div>
            </template>
            <template #default>
              <div>
                {{message.createtime_str}}
              </div>
            </template>
          </van-cell>
          <van-cell :border="false">
            <template #title>
              <div v-html="message.content">{{message.content}}</div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <van-list
        v-if="false"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="main_list"
          v-for="(item, index) in list"
          :key="index"
        >
          <van-cell-group :border="false">
            <van-cell :border="false">
              <template #title>
                <div class="van-ellipsis"></div>
              </template>
              <template #default>
                <div>
                  2020-10-24 18:10:12
                </div>
              </template>
            </van-cell>
            <van-cell :border="false">
              <template #title>
                这是一段最多显示一行的文字，多余的内容会被省略
                这是一段最多显示一行的文字，多余的内容会被省略
                这是一段最多显示一行的文字，多余的内容会被省略
                这是一段最多显示一行的文字，多余的内容会被省略
                这是一段最多显示一行的文字，多余的内容会被省略
                这是一段最多显示一行的文字，多余的内容会被省略
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "MessageDetails",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      list: [],
      currSize: 20,
      currOffset: 0,
      loading: false,
      finished: false,
      id: 0,
      title: "",
      message: {}
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, this.title);
    this.init();
  },
  methods: {
    init(){
      console.log("sss");
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/message/get_details",
          _that.$qs.stringify({
            id: _that.id,
            size: _that.currSize,
            offset: _that.currOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            _that.message = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    onLoad() {
      console.log("sss");
      let _that = null;
      _that = this;
      _that.finished = true;

      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/message/get_details",
          _that.$qs.stringify({
            id: _that.id,
            size: _that.currSize,
            offset: _that.currOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            if (_that.currOffset == 0) {
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if (res.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
              _that.finished = false;
            } else {
              _that.currOffset += res.data.data.length;
              _that.finished = true;
            }
          }else{
            _that.finished = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
}
</script>
<style lang="less">
.messagedetails {
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding-bottom: 60px;
    padding-top: 50px;
    overflow: hidden;
    .main_list {
      overflow: hidden;
      margin-top: 10px;
      background-color: #fff;

      .van-cell {
        overflow: hidden;
        .van-cell__title {
          flex: 1;
          text-align: left;
          overflow: hidden;
          div {
            width: 100%;
            overflow: hidden;
            img {
              max-width: 100%;
            }
          }
        }
        &:last-of-type {
          padding-top: 0;
        }
      }
    }
  }
}
</style>